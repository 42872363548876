(function (window, Handlebars) {
   Handlebars.registerHelper('equal', function (lvalue, rvalue, options) {
      if (arguments.length < 3)
         throw new Error("Handlebars Helper equal needs 2 parameters");
      if (lvalue != rvalue) {
         return options.inverse(this);
      } else {
         return options.fn(this);
      }
   });

   Handlebars.registerHelper('localizedString', function (stringId) {
      var propNames = ["techsmith", "strings"].concat(stringId.split('.'));
      var propLevel = "window";
      var stringObject = window;

      for (var i = 0; i < propNames.length; i++) {
         var prop = propNames[i];
         propLevel += '.' + prop;
         stringObject = stringObject[prop];

         if (stringObject === undefined) {
            throw ("Failed to resolve localized string, " + propLevel + " is undefined");
         }
      }

      // Grab all arguments after the first(format) string
      var args = _(arguments).toArray().slice(1);
      // Remove the "hash" object added by handlebars
      args.pop();
      
      if (args.length > 0) {
         return String.prototype.format.apply(stringObject,args);
      } else {
         return stringObject;
      }
   });

   Handlebars.registerHelper('ifHasMultipleAnswers', function (answerList, options) {
      if (answerList && answerList.length > 1) {
         return options.fn(this);
      } else {
         return options.inverse(this);
      }
   });
}(window, Handlebars));
