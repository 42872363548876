(function() {
var template = Handlebars.template, templates = Handlebars.templates = Handlebars.templates || {};
templates['assessmentItem'] = template({"1":function(container,depth0,helpers,partials,data) {
    return "   class=\"touchColumn\"\r\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <li>\r\n         <a href=\"/Assessment/Edit/"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"id","hash":{},"data":data,"loc":{"start":{"line":14,"column":35},"end":{"line":14,"column":41}}}) : helper)))
    + "\">\r\n            <span class=\"edit-icon\"></span>\r\n         </a>\r\n      </li>\r\n";
},"5":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <li>\r\n         <a href=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"playerLink") || (depth0 != null ? lookupProperty(depth0,"playerLink") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"playerLink","hash":{},"data":data,"loc":{"start":{"line":26,"column":18},"end":{"line":26,"column":32}}}) : helper)))
    + "\">\r\n            <span class=\"preview-icon\"></span>\r\n         </a>\r\n      </li>\r\n";
},"7":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "   <a href=\"/Assessment/Edit/"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"id","hash":{},"data":data,"loc":{"start":{"line":35,"column":29},"end":{"line":35,"column":35}}}) : helper)))
    + "\">\r\n";
},"9":function(container,depth0,helpers,partials,data) {
    return "   </a>\r\n";
},"11":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "   <p class=\"just-added-text\">\r\n      "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"quizJustLinkedMessage") || (depth0 != null ? lookupProperty(depth0,"quizJustLinkedMessage") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"quizJustLinkedMessage","hash":{},"data":data,"loc":{"start":{"line":43,"column":6},"end":{"line":43,"column":31}}}) : helper)))
    + "\r\n   </p>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<td\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"touchDevice") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":3},"end":{"line":4,"column":10}}})) != null ? stack1 : "")
    + "   >\r\n   <ul>\r\n      <li>\r\n         <a href=\"/Report/Show/"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":8,"column":31},"end":{"line":8,"column":37}}}) : helper)))
    + "\">\r\n            <span class=\"report-icon\"></span>\r\n         </a>\r\n      </li>\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"canEdit") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":6},"end":{"line":18,"column":13}}})) != null ? stack1 : "")
    + "      <li class=\"delete-fathom-button\">\r\n         <a href=\"#\">\r\n            <span class=\"delete-icon\"></span>\r\n         </a>\r\n      </li>\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasBeenPublished") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":24,"column":3},"end":{"line":30,"column":13}}})) != null ? stack1 : "")
    + "   </ul>\r\n</td>\r\n<td class=\"quiz-title quiz-title-content\">\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"canEdit") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":34,"column":3},"end":{"line":36,"column":13}}})) != null ? stack1 : "")
    + "      "
    + alias4(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":37,"column":6},"end":{"line":37,"column":15}}}) : helper)))
    + "\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"canEdit") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":38,"column":6},"end":{"line":40,"column":10}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isJustAdded") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":41,"column":3},"end":{"line":45,"column":10}}})) != null ? stack1 : "")
    + "</td>\r\n<td class=\"create-date\">\r\n   "
    + alias4(((helper = (helper = lookupProperty(helpers,"createDate") || (depth0 != null ? lookupProperty(depth0,"createDate") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"createDate","hash":{},"data":data,"loc":{"start":{"line":48,"column":3},"end":{"line":48,"column":17}}}) : helper)))
    + "\r\n</td>";
},"useData":true});

templates['assessmentTable'] = template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<h1 class=\"sixteen l-centered-content\">Quizzes</h1>\r\n<table id=\"assessments-table\">\r\n   <thead>\r\n      <tr>\r\n         <th data-sorter=\"false\">"
    + alias3((lookupProperty(helpers,"localizedString")||(depth0 && lookupProperty(depth0,"localizedString"))||alias2).call(alias1,"dashboard.tableHeaderActions",{"name":"localizedString","hash":{},"data":data,"loc":{"start":{"line":5,"column":33},"end":{"line":5,"column":83}}}))
    + "</th>\r\n         <th class=\"quiz-title pointer\">"
    + alias3((lookupProperty(helpers,"localizedString")||(depth0 && lookupProperty(depth0,"localizedString"))||alias2).call(alias1,"dashboard.tableHeaderTitle",{"name":"localizedString","hash":{},"data":data,"loc":{"start":{"line":6,"column":40},"end":{"line":6,"column":88}}}))
    + "</th>\r\n         <th class=\"create-date pointer\">"
    + alias3((lookupProperty(helpers,"localizedString")||(depth0 && lookupProperty(depth0,"localizedString"))||alias2).call(alias1,"dashboard.tableHeaderDateCreated",{"name":"localizedString","hash":{},"data":data,"loc":{"start":{"line":7,"column":41},"end":{"line":7,"column":95}}}))
    + "</th>\r\n      </tr>\r\n   </thead>\r\n   <tbody class=\"assessment-list\"></tbody>\r\n</table>";
},"useData":true});

templates['emptyAssessmentTable'] = template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<h1 class=\"sixteen l-centered-content\">"
    + alias3((lookupProperty(helpers,"localizedString")||(depth0 && lookupProperty(depth0,"localizedString"))||alias2).call(alias1,"dashboard.dashboardEmpty",{"name":"localizedString","hash":{},"data":data,"loc":{"start":{"line":1,"column":39},"end":{"line":1,"column":85}}}))
    + "</h1>\r\n<div class=\"empty-assessment-list\">\r\n   <div class=\"dashboard-message\">\r\n      "
    + alias3((lookupProperty(helpers,"localizedString")||(depth0 && lookupProperty(depth0,"localizedString"))||alias2).call(alias1,"dashboard.dashboardEmptyMessage",{"name":"localizedString","hash":{},"data":data,"loc":{"start":{"line":4,"column":6},"end":{"line":4,"column":59}}}))
    + "\r\n   </div>\r\n   <div class=\"product-box\">\r\n      <img class=\"product-icon\" src=\"/Content/Images/CamtasiaIcon@2x.png\"></img>\r\n      "
    + alias3((lookupProperty(helpers,"localizedString")||(depth0 && lookupProperty(depth0,"localizedString"))||alias2).call(alias1,"dashboard.camtasia",{"name":"localizedString","hash":{},"data":data,"loc":{"start":{"line":8,"column":6},"end":{"line":8,"column":46}}}))
    + "\r\n      <header>"
    + alias3((lookupProperty(helpers,"localizedString")||(depth0 && lookupProperty(depth0,"localizedString"))||alias2).call(alias1,"dashboard.listHeader",{"name":"localizedString","hash":{},"data":data,"loc":{"start":{"line":9,"column":14},"end":{"line":9,"column":56}}}))
    + "</header>\r\n      <ol class=\"product-list\">\r\n         <li>"
    + alias3((lookupProperty(helpers,"localizedString")||(depth0 && lookupProperty(depth0,"localizedString"))||alias2).call(alias1,"dashboard.camtasiaList1",{"name":"localizedString","hash":{},"data":data,"loc":{"start":{"line":11,"column":13},"end":{"line":11,"column":58}}}))
    + "<a href=\"https://www.techsmith.com/tutorial-camtasia-9-3-quizzing.html\">Learn how.</a></li>\r\n         <li>"
    + alias3((lookupProperty(helpers,"localizedString")||(depth0 && lookupProperty(depth0,"localizedString"))||alias2).call(alias1,"dashboard.camtasiaList2",{"name":"localizedString","hash":{},"data":data,"loc":{"start":{"line":12,"column":13},"end":{"line":12,"column":58}}}))
    + "</li>\r\n         <li>"
    + alias3((lookupProperty(helpers,"localizedString")||(depth0 && lookupProperty(depth0,"localizedString"))||alias2).call(alias1,"dashboard.camtasiaList3",{"name":"localizedString","hash":{},"data":data,"loc":{"start":{"line":13,"column":13},"end":{"line":13,"column":58}}}))
    + "</li>\r\n      </ol>\r\n   </div>\r\n   <div class=\"product-box\">\r\n      <img class=\"product-icon\" src=\"/Content/Images/RelayIcon@2x.png\"></img>\r\n      "
    + alias3((lookupProperty(helpers,"localizedString")||(depth0 && lookupProperty(depth0,"localizedString"))||alias2).call(alias1,"dashboard.relay",{"name":"localizedString","hash":{},"data":data,"loc":{"start":{"line":18,"column":6},"end":{"line":18,"column":43}}}))
    + "\r\n      <header>"
    + alias3((lookupProperty(helpers,"localizedString")||(depth0 && lookupProperty(depth0,"localizedString"))||alias2).call(alias1,"dashboard.listHeader",{"name":"localizedString","hash":{},"data":data,"loc":{"start":{"line":19,"column":14},"end":{"line":19,"column":56}}}))
    + "</header>\r\n      <ol class=\"product-list\">\r\n         <li>"
    + alias3((lookupProperty(helpers,"localizedString")||(depth0 && lookupProperty(depth0,"localizedString"))||alias2).call(alias1,"dashboard.relayList1",{"name":"localizedString","hash":{},"data":data,"loc":{"start":{"line":21,"column":13},"end":{"line":21,"column":55}}}))
    + "</li>\r\n         <li>"
    + alias3((lookupProperty(helpers,"localizedString")||(depth0 && lookupProperty(depth0,"localizedString"))||alias2).call(alias1,"dashboard.relayList2",{"name":"localizedString","hash":{},"data":data,"loc":{"start":{"line":22,"column":13},"end":{"line":22,"column":55}}}))
    + "</li>\r\n         <li>"
    + alias3((lookupProperty(helpers,"localizedString")||(depth0 && lookupProperty(depth0,"localizedString"))||alias2).call(alias1,"dashboard.relayList3",{"name":"localizedString","hash":{},"data":data,"loc":{"start":{"line":23,"column":13},"end":{"line":23,"column":55}}}))
    + "</li>\r\n      </ol>\r\n   </div>\r\n</div>\r\n";
},"useData":true});
}());