(function() {
var template = Handlebars.template, templates = Handlebars.templates = Handlebars.templates || {};
templates['defaultQuestionStats'] = template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <tr>\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasName") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.program(4, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":12,"column":9},"end":{"line":19,"column":9}}})) != null ? stack1 : "")
    + "         <td>\r\n"
    + ((stack1 = (lookupProperty(helpers,"equal")||(depth0 && lookupProperty(depth0,"equal"))||container.hooks.helperMissing).call(alias1,(depth0 != null ? lookupProperty(depth0,"answer") : depth0),(depths[1] != null ? lookupProperty(depths[1],"noResponseString") : depths[1]),{"name":"equal","hash":{},"fn":container.program(9, data, 0, blockParams, depths),"inverse":container.program(11, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":21,"column":12},"end":{"line":25,"column":22}}})) != null ? stack1 : "")
    + "         </td>\r\n      </tr>\r\n";
},"2":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "         <td>"
    + alias4(((helper = (helper = lookupProperty(helpers,"firstName") || (depth0 != null ? lookupProperty(depth0,"firstName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"firstName","hash":{},"data":data,"loc":{"start":{"line":13,"column":13},"end":{"line":13,"column":26}}}) : helper)))
    + " "
    + alias4(((helper = (helper = lookupProperty(helpers,"lastName") || (depth0 != null ? lookupProperty(depth0,"lastName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"lastName","hash":{},"data":data,"loc":{"start":{"line":13,"column":27},"end":{"line":13,"column":39}}}) : helper)))
    + "<br/><span class=\"deemphasise\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"email") || (depth0 != null ? lookupProperty(depth0,"email") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"email","hash":{},"data":data,"loc":{"start":{"line":13,"column":70},"end":{"line":13,"column":79}}}) : helper)))
    + "</span></td>\r\n	 ";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " "
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"email") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":14,"column":11},"end":{"line":18,"column":9}}})) != null ? stack1 : "");
},"5":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\r\n         <td><span class=\"deemphasise\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"email") || (depth0 != null ? lookupProperty(depth0,"email") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"email","hash":{},"data":data,"loc":{"start":{"line":15,"column":39},"end":{"line":15,"column":48}}}) : helper)))
    + "</span></td>\r\n";
},"7":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "         <td>"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"lastName") || (depth0 != null ? lookupProperty(depth0,"lastName") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"lastName","hash":{},"data":data,"loc":{"start":{"line":17,"column":13},"end":{"line":17,"column":25}}}) : helper)))
    + "</td>\r\n";
},"9":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "               <i>"
    + container.escapeExpression((lookupProperty(helpers,"localizedString")||(depth0 && lookupProperty(depth0,"localizedString"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"report.noAnswerGiven",{"name":"localizedString","hash":{},"data":data,"loc":{"start":{"line":22,"column":18},"end":{"line":22,"column":60}}}))
    + "</i>\r\n";
},"11":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "               "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"answer") || (depth0 != null ? lookupProperty(depth0,"answer") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"answer","hash":{},"data":data,"loc":{"start":{"line":24,"column":15},"end":{"line":24,"column":25}}}) : helper)))
    + "\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), alias4=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<h3 data-question-type=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"question") : depth0)) != null ? lookupProperty(stack1,"type") : stack1), depth0))
    + "\"><span class=\"question-type-icon "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"question") : depth0)) != null ? lookupProperty(stack1,"type") : stack1), depth0))
    + "\"></span><span class=\"question-text\"><span class=\"red-text\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"question") : depth0)) != null ? lookupProperty(stack1,"number") : stack1), depth0))
    + "</span>. "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"question") : depth0)) != null ? lookupProperty(stack1,"text") : stack1), depth0))
    + "</span></h3>\r\n<table class=\"question-response-list\">\r\n   <thead>\r\n      <tr>\r\n         <th>"
    + alias2((lookupProperty(helpers,"localizedString")||(depth0 && lookupProperty(depth0,"localizedString"))||alias4).call(alias3,"report.questionsViewers",{"name":"localizedString","hash":{},"data":data,"loc":{"start":{"line":5,"column":13},"end":{"line":5,"column":58}}}))
    + "</th>\r\n         <th>"
    + alias2((lookupProperty(helpers,"localizedString")||(depth0 && lookupProperty(depth0,"localizedString"))||alias4).call(alias3,"report.questionsAnswers",{"name":"localizedString","hash":{},"data":data,"loc":{"start":{"line":6,"column":13},"end":{"line":6,"column":58}}}))
    + "</th>\r\n      </tr>\r\n   </thead>\r\n   <tbody>\r\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias3,(depth0 != null ? lookupProperty(depth0,"answers") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":6},"end":{"line":28,"column":15}}})) != null ? stack1 : "")
    + "   </tbody>\r\n</table>";
},"useData":true,"useDepths":true});

templates['generalStats'] = template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                   <li id=\"quizAverageScore\">"
    + alias3((lookupProperty(helpers,"localizedString")||(depth0 && lookupProperty(depth0,"localizedString"))||alias2).call(alias1,"report.averageCorrect",{"name":"localizedString","hash":{},"data":data,"loc":{"start":{"line":21,"column":45},"end":{"line":21,"column":88}}}))
    + ":&nbsp;<strong>"
    + alias3(((helper = (helper = lookupProperty(helpers,"averagePercentCorrect") || (depth0 != null ? lookupProperty(depth0,"averagePercentCorrect") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"averagePercentCorrect","hash":{},"data":data,"loc":{"start":{"line":21,"column":103},"end":{"line":21,"column":128}}}) : helper)))
    + "%</strong></li>\r\n                   <li id=\"quizHighScore\">"
    + alias3((lookupProperty(helpers,"localizedString")||(depth0 && lookupProperty(depth0,"localizedString"))||alias2).call(alias1,"report.topScore",{"name":"localizedString","hash":{},"data":data,"loc":{"start":{"line":22,"column":42},"end":{"line":22,"column":79}}}))
    + ":&nbsp;<strong>"
    + alias3(((helper = (helper = lookupProperty(helpers,"highScore") || (depth0 != null ? lookupProperty(depth0,"highScore") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"highScore","hash":{},"data":data,"loc":{"start":{"line":22,"column":94},"end":{"line":22,"column":107}}}) : helper)))
    + "/"
    + alias3(((helper = (helper = lookupProperty(helpers,"numberOfScoredQuestions") || (depth0 != null ? lookupProperty(depth0,"numberOfScoredQuestions") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"numberOfScoredQuestions","hash":{},"data":data,"loc":{"start":{"line":22,"column":108},"end":{"line":22,"column":135}}}) : helper)))
    + "</strong></li>\r\n                   <li id=\"quizLowScore\">"
    + alias3((lookupProperty(helpers,"localizedString")||(depth0 && lookupProperty(depth0,"localizedString"))||alias2).call(alias1,"report.lowestScore",{"name":"localizedString","hash":{},"data":data,"loc":{"start":{"line":23,"column":41},"end":{"line":23,"column":81}}}))
    + ":&nbsp;<strong>"
    + alias3(((helper = (helper = lookupProperty(helpers,"lowScore") || (depth0 != null ? lookupProperty(depth0,"lowScore") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"lowScore","hash":{},"data":data,"loc":{"start":{"line":23,"column":96},"end":{"line":23,"column":108}}}) : helper)))
    + "/"
    + alias3(((helper = (helper = lookupProperty(helpers,"numberOfScoredQuestions") || (depth0 != null ? lookupProperty(depth0,"numberOfScoredQuestions") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"numberOfScoredQuestions","hash":{},"data":data,"loc":{"start":{"line":23,"column":109},"end":{"line":23,"column":136}}}) : helper)))
    + "</strong></li>\r\n";
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "             <div class=\"right\"><label class=\"graphTitle\">"
    + container.escapeExpression((lookupProperty(helpers,"localizedString")||(depth0 && lookupProperty(depth0,"localizedString"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"report.averageScore",{"name":"localizedString","hash":{},"data":data,"loc":{"start":{"line":28,"column":58},"end":{"line":28,"column":99}}}))
    + "</label><div id=\"averageCorrectGraph\"></div></div>\r\n";
},"5":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "               <div id=\"graphsContainer\">\r\n                  <div class=\"left\"><label class=\"graphTitle\">"
    + container.escapeExpression((lookupProperty(helpers,"localizedString")||(depth0 && lookupProperty(depth0,"localizedString"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"report.averageResultsQuestion",{"name":"localizedString","hash":{},"data":data,"loc":{"start":{"line":34,"column":62},"end":{"line":34,"column":113}}}))
    + "</label><div id=\"questionGraph\"></div></div>\r\n                  <div class=\"clear\"></div>\r\n               </div>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div>\r\n   <div id=\"generalStatsContainer\" class=\"generalTabContainer\">\r\n      <div class=\"generalTabHeader\">\r\n         <div class=\"generalTabContent clearfix\">\r\n            <div class=\"left\">Download Summary</div>\r\n            <div id=\"download-report-buttons\">\r\n               <a id=\"download-details-report\" href=\"/Report/GetDetailsCsv/"
    + alias4(((helper = (helper = lookupProperty(helpers,"quizId") || (depth0 != null ? lookupProperty(depth0,"quizId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"quizId","hash":{},"data":data,"loc":{"start":{"line":7,"column":75},"end":{"line":7,"column":85}}}) : helper)))
    + "\"><span class=\"download-report-button-icon\"></span>"
    + alias4((lookupProperty(helpers,"localizedString")||(depth0 && lookupProperty(depth0,"localizedString"))||alias2).call(alias1,"report.downloadDetailsReport",{"name":"localizedString","hash":{},"data":data,"loc":{"start":{"line":7,"column":136},"end":{"line":7,"column":186}}}))
    + "</a>\r\n               <a id=\"download-summary-report\" href=\"/Report/GetSummaryCsv/"
    + alias4(((helper = (helper = lookupProperty(helpers,"quizId") || (depth0 != null ? lookupProperty(depth0,"quizId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"quizId","hash":{},"data":data,"loc":{"start":{"line":8,"column":75},"end":{"line":8,"column":85}}}) : helper)))
    + "\"><span class=\"download-report-button-icon\"></span>"
    + alias4((lookupProperty(helpers,"localizedString")||(depth0 && lookupProperty(depth0,"localizedString"))||alias2).call(alias1,"report.downloadSummaryReport",{"name":"localizedString","hash":{},"data":data,"loc":{"start":{"line":8,"column":136},"end":{"line":8,"column":186}}}))
    + "</a>\r\n            </div>\r\n         </div>\r\n       </div>\r\n       <div class=\"clear\"></div>\r\n       <div class=\"generalTabContent\">\r\n          <div id=\"generalStats\">\r\n             <div class=\"left\">\r\n                <label>General Information</label>\r\n                <ul>\r\n                   <li id=\"quizTotalResponses\">"
    + alias4((lookupProperty(helpers,"localizedString")||(depth0 && lookupProperty(depth0,"localizedString"))||alias2).call(alias1,"report.numberQuizzesTaken",{"name":"localizedString","hash":{},"data":data,"loc":{"start":{"line":18,"column":47},"end":{"line":18,"column":94}}}))
    + ":&nbsp;<strong>"
    + alias4(((helper = (helper = lookupProperty(helpers,"quizzesTaken") || (depth0 != null ? lookupProperty(depth0,"quizzesTaken") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"quizzesTaken","hash":{},"data":data,"loc":{"start":{"line":18,"column":109},"end":{"line":18,"column":125}}}) : helper)))
    + "</strong></li>\r\n                   <li id=\"quizAveragePercentageViewed\">"
    + alias4((lookupProperty(helpers,"localizedString")||(depth0 && lookupProperty(depth0,"localizedString"))||alias2).call(alias1,"report.averageContentViewed",{"name":"localizedString","hash":{},"data":data,"loc":{"start":{"line":19,"column":56},"end":{"line":19,"column":105}}}))
    + ":&nbsp;<strong>"
    + alias4(((helper = (helper = lookupProperty(helpers,"averagePercentageOfVideoViewed") || (depth0 != null ? lookupProperty(depth0,"averagePercentageOfVideoViewed") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"averagePercentageOfVideoViewed","hash":{},"data":data,"loc":{"start":{"line":19,"column":120},"end":{"line":19,"column":154}}}) : helper)))
    + "%</strong></li>\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasScoredQuestions") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":16},"end":{"line":24,"column":23}}})) != null ? stack1 : "")
    + "                </ul>\r\n             </div>\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasScoredQuestions") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":27,"column":13},"end":{"line":29,"column":20}}})) != null ? stack1 : "")
    + "          </div>\r\n          <div class=\"clear\"></div>\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasScoredQuestions") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":32,"column":12},"end":{"line":37,"column":19}}})) != null ? stack1 : "")
    + "       </div>\r\n    </div>\r\n</div>";
},"useData":true});

templates['multipleChoiceQuestionStats'] = template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <tr>\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasName") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.program(4, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":15,"column":15},"end":{"line":27,"column":22}}})) != null ? stack1 : "")
    + "               <td>\r\n"
    + ((stack1 = (lookupProperty(helpers,"equal")||(depth0 && lookupProperty(depth0,"equal"))||container.hooks.helperMissing).call(alias1,(depth0 != null ? lookupProperty(depth0,"answer") : depth0),(depths[1] != null ? lookupProperty(depths[1],"noResponseString") : depths[1]),{"name":"equal","hash":{},"fn":container.program(12, data, 0, blockParams, depths),"inverse":container.program(14, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":29,"column":18},"end":{"line":33,"column":28}}})) != null ? stack1 : "")
    + "               </td>\r\n            </tr>\r\n";
},"2":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                  <td>"
    + alias4(((helper = (helper = lookupProperty(helpers,"firstName") || (depth0 != null ? lookupProperty(depth0,"firstName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"firstName","hash":{},"data":data,"loc":{"start":{"line":16,"column":22},"end":{"line":16,"column":35}}}) : helper)))
    + " "
    + alias4(((helper = (helper = lookupProperty(helpers,"lastName") || (depth0 != null ? lookupProperty(depth0,"lastName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"lastName","hash":{},"data":data,"loc":{"start":{"line":16,"column":36},"end":{"line":16,"column":48}}}) : helper)))
    + "<br /><span class=\"deemphasise\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"email") || (depth0 != null ? lookupProperty(depth0,"email") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"email","hash":{},"data":data,"loc":{"start":{"line":16,"column":80},"end":{"line":16,"column":89}}}) : helper)))
    + "</span></td>\r\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"email") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":18,"column":18},"end":{"line":26,"column":25}}})) != null ? stack1 : "");
},"5":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                     <td><span class=\"deemphasise\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"email") || (depth0 != null ? lookupProperty(depth0,"email") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"email","hash":{},"data":data,"loc":{"start":{"line":19,"column":51},"end":{"line":19,"column":60}}}) : helper)))
    + "</span></td>\r\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"displayName") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.program(10, data, 0),"data":data,"loc":{"start":{"line":21,"column":21},"end":{"line":25,"column":28}}})) != null ? stack1 : "");
},"8":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <td>"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"displayName") || (depth0 != null ? lookupProperty(depth0,"displayName") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"displayName","hash":{},"data":data,"loc":{"start":{"line":22,"column":28},"end":{"line":22,"column":43}}}) : helper)))
    + "</td>\r\n";
},"10":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <td>"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"lastName") || (depth0 != null ? lookupProperty(depth0,"lastName") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"lastName","hash":{},"data":data,"loc":{"start":{"line":24,"column":28},"end":{"line":24,"column":40}}}) : helper)))
    + "</td>\r\n";
},"12":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                     <i>"
    + container.escapeExpression((lookupProperty(helpers,"localizedString")||(depth0 && lookupProperty(depth0,"localizedString"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"report.noAnswerGiven",{"name":"localizedString","hash":{},"data":data,"loc":{"start":{"line":30,"column":24},"end":{"line":30,"column":66}}}))
    + "</i>\r\n";
},"14":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"answer") || (depth0 != null ? lookupProperty(depth0,"answer") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"answer","hash":{},"data":data,"loc":{"start":{"line":32,"column":12},"end":{"line":32,"column":22}}}) : helper)))
    + "\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), alias4=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<h3 data-question-type=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"question") : depth0)) != null ? lookupProperty(stack1,"type") : stack1), depth0))
    + "\"><span class=\"question-type-icon "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"question") : depth0)) != null ? lookupProperty(stack1,"type") : stack1), depth0))
    + "\"></span><span\r\n      class=\"question-text\"><span class=\"red-text\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"question") : depth0)) != null ? lookupProperty(stack1,"number") : stack1), depth0))
    + "</span>. "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"question") : depth0)) != null ? lookupProperty(stack1,"text") : stack1), depth0))
    + "</span></h3>\r\n<div class=\"multiple-choice-question-stats\">\r\n   <div class=\"answerChart\"></div>\r\n   <table class=\"question-response-list\">\r\n      <thead>\r\n         <tr>\r\n            <th>"
    + alias2((lookupProperty(helpers,"localizedString")||(depth0 && lookupProperty(depth0,"localizedString"))||alias4).call(alias3,"report.questionsViewers",{"name":"localizedString","hash":{},"data":data,"loc":{"start":{"line":8,"column":16},"end":{"line":8,"column":61}}}))
    + "</th>\r\n            <th>"
    + alias2((lookupProperty(helpers,"localizedString")||(depth0 && lookupProperty(depth0,"localizedString"))||alias4).call(alias3,"report.questionsAnswers",{"name":"localizedString","hash":{},"data":data,"loc":{"start":{"line":9,"column":16},"end":{"line":9,"column":61}}}))
    + "</th>\r\n         </tr>\r\n      </thead>\r\n      <tbody>\r\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias3,(depth0 != null ? lookupProperty(depth0,"answers") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":9},"end":{"line":36,"column":18}}})) != null ? stack1 : "")
    + "      </tbody>\r\n   </table>\r\n</div>";
},"useData":true,"useDepths":true});

templates['multipleChoiceQuestionStatsRow'] = template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"multipleChoiceRow\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"name","hash":{},"data":data,"loc":{"start":{"line":1,"column":31},"end":{"line":1,"column":39}}}) : helper)))
    + "</div>";
},"useData":true});

templates['questionsStats'] = template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<span class=\"amazing-quiz-image\"></span>\r\n<div class=\"amazing-quiz-text-container\">\r\n   <div class=\"amazing-quiz-text\">\r\n      "
    + alias3((lookupProperty(helpers,"localizedString")||(depth0 && lookupProperty(depth0,"localizedString"))||alias2).call(alias1,"report.amazingQuizText1",{"name":"localizedString","hash":{},"data":data,"loc":{"start":{"line":5,"column":6},"end":{"line":5,"column":51}}}))
    + "<a id=\"download-details-report\" href=\"/Report/GetDetailsCsv/"
    + alias3(((helper = (helper = lookupProperty(helpers,"quizId") || (depth0 != null ? lookupProperty(depth0,"quizId") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"quizId","hash":{},"data":data,"loc":{"start":{"line":5,"column":111},"end":{"line":5,"column":121}}}) : helper)))
    + "\">"
    + alias3((lookupProperty(helpers,"localizedString")||(depth0 && lookupProperty(depth0,"localizedString"))||alias2).call(alias1,"report.amazingQuizDownloadDetailsCsv",{"name":"localizedString","hash":{},"data":data,"loc":{"start":{"line":5,"column":123},"end":{"line":5,"column":181}}}))
    + "</a>"
    + alias3((lookupProperty(helpers,"localizedString")||(depth0 && lookupProperty(depth0,"localizedString"))||alias2).call(alias1,"report.amazingQuizText2",{"name":"localizedString","hash":{},"data":data,"loc":{"start":{"line":5,"column":185},"end":{"line":5,"column":230}}}))
    + "\r\n   </div>\r\n</div>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isSummaryOnly") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":8,"column":7}}})) != null ? stack1 : "")
    + "<div id=\"questionStats\"></div>";
},"useData":true});

templates['quizViewer'] = template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return alias4(((helper = (helper = lookupProperty(helpers,"lastName") || (depth0 != null ? lookupProperty(depth0,"lastName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"lastName","hash":{},"data":data,"loc":{"start":{"line":4,"column":59},"end":{"line":4,"column":71}}}) : helper)))
    + ", "
    + alias4(((helper = (helper = lookupProperty(helpers,"firstName") || (depth0 != null ? lookupProperty(depth0,"firstName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"firstName","hash":{},"data":data,"loc":{"start":{"line":4,"column":73},"end":{"line":4,"column":86}}}) : helper)));
},"3":function(container,depth0,helpers,partials,data) {
    return "";
},"5":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      "
    + alias4(((helper = (helper = lookupProperty(helpers,"lastName") || (depth0 != null ? lookupProperty(depth0,"lastName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"lastName","hash":{},"data":data,"loc":{"start":{"line":7,"column":6},"end":{"line":7,"column":18}}}) : helper)))
    + ", "
    + alias4(((helper = (helper = lookupProperty(helpers,"firstName") || (depth0 != null ? lookupProperty(depth0,"firstName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"firstName","hash":{},"data":data,"loc":{"start":{"line":7,"column":20},"end":{"line":7,"column":33}}}) : helper)))
    + "<br/>"
    + alias4(((helper = (helper = lookupProperty(helpers,"email") || (depth0 != null ? lookupProperty(depth0,"email") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"email","hash":{},"data":data,"loc":{"start":{"line":7,"column":38},"end":{"line":7,"column":47}}}) : helper)))
    + "\r\n";
},"7":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"email") || (depth0 != null ? lookupProperty(depth0,"email") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"email","hash":{},"data":data,"loc":{"start":{"line":9,"column":6},"end":{"line":9,"column":15}}}) : helper)))
    + "\r\n   ";
},"9":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<td>"
    + alias4(((helper = (helper = lookupProperty(helpers,"numberCorrect") || (depth0 != null ? lookupProperty(depth0,"numberCorrect") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"numberCorrect","hash":{},"data":data,"loc":{"start":{"line":14,"column":4},"end":{"line":14,"column":21}}}) : helper)))
    + " ("
    + alias4(((helper = (helper = lookupProperty(helpers,"percentageCorrect") || (depth0 != null ? lookupProperty(depth0,"percentageCorrect") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"percentageCorrect","hash":{},"data":data,"loc":{"start":{"line":14,"column":23},"end":{"line":14,"column":44}}}) : helper)))
    + "%)</td>\r\n<td>"
    + alias4(((helper = (helper = lookupProperty(helpers,"percentileRank") || (depth0 != null ? lookupProperty(depth0,"percentileRank") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"percentileRank","hash":{},"data":data,"loc":{"start":{"line":15,"column":4},"end":{"line":15,"column":22}}}) : helper)))
    + "</td>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<td class=\"viewerName displayNameDisplay\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"displayName") || (depth0 != null ? lookupProperty(depth0,"displayName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"displayName","hash":{},"data":data,"loc":{"start":{"line":1,"column":42},"end":{"line":1,"column":57}}}) : helper)))
    + "</td>\r\n<td class=\"viewerName separateFirstAndLastNameDisplay\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"lastName") || (depth0 != null ? lookupProperty(depth0,"lastName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"lastName","hash":{},"data":data,"loc":{"start":{"line":2,"column":55},"end":{"line":2,"column":67}}}) : helper)))
    + "</td>\r\n<td class=\"viewerName separateFirstAndLastNameDisplay\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"firstName") || (depth0 != null ? lookupProperty(depth0,"firstName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"firstName","hash":{},"data":data,"loc":{"start":{"line":3,"column":55},"end":{"line":3,"column":68}}}) : helper)))
    + "</td>\r\n<td class=\"firstAndLastNameTogetherDisplay\">"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasName") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":4,"column":44},"end":{"line":4,"column":101}}})) != null ? stack1 : "")
    + "</td>\r\n<td class=\"viewIdentityDisplay\">\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasName") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":6,"column":3},"end":{"line":10,"column":10}}})) != null ? stack1 : "")
    + "</td>\r\n<td class=\"emailDisplay\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"email") || (depth0 != null ? lookupProperty(depth0,"email") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"email","hash":{},"data":data,"loc":{"start":{"line":11,"column":25},"end":{"line":11,"column":34}}}) : helper)))
    + "</td>\r\n<td><span class=\"sort-by\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"videoStartTimeSortable") || (depth0 != null ? lookupProperty(depth0,"videoStartTimeSortable") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"videoStartTimeSortable","hash":{},"data":data,"loc":{"start":{"line":12,"column":26},"end":{"line":12,"column":52}}}) : helper)))
    + "</span><span class=\"long-date\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"videoStartTimeString") || (depth0 != null ? lookupProperty(depth0,"videoStartTimeString") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"videoStartTimeString","hash":{},"data":data,"loc":{"start":{"line":12,"column":83},"end":{"line":12,"column":107}}}) : helper)))
    + "</span><span class=\"short-date\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"videoStartTimeStringShort") || (depth0 != null ? lookupProperty(depth0,"videoStartTimeStringShort") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"videoStartTimeStringShort","hash":{},"data":data,"loc":{"start":{"line":12,"column":139},"end":{"line":12,"column":168}}}) : helper)))
    + "</span></td>\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasScoredQuestions") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":0},"end":{"line":16,"column":7}}})) != null ? stack1 : "")
    + "<td>"
    + alias4(((helper = (helper = lookupProperty(helpers,"percentageOfVideoWatched") || (depth0 != null ? lookupProperty(depth0,"percentageOfVideoWatched") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"percentageOfVideoWatched","hash":{},"data":data,"loc":{"start":{"line":17,"column":4},"end":{"line":17,"column":32}}}) : helper)))
    + "%</td>\r\n";
},"useData":true});

templates['viewerResponseData'] = template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " ("
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"email") || (depth0 != null ? lookupProperty(depth0,"email") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"email","hash":{},"data":data,"loc":{"start":{"line":1,"column":45},"end":{"line":1,"column":54}}}) : helper)))
    + ")";
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"viewer-data-title\">\r\n   <label class=\"graphTitle\">"
    + container.escapeExpression((lookupProperty(helpers,"localizedString")||(depth0 && lookupProperty(depth0,"localizedString"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"report.viewerScoreHeader",{"name":"localizedString","hash":{},"data":data,"loc":{"start":{"line":4,"column":29},"end":{"line":4,"column":75}}}))
    + "</label>\r\n</div>\r\n<div id=\"viewer-data-score\"></div>\r\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "   <div data-accordion-name=\"Incorrect Responses\">\r\n      <h3>"
    + alias3((lookupProperty(helpers,"localizedString")||(depth0 && lookupProperty(depth0,"localizedString"))||alias2).call(alias1,"report.viewerIncorrectResponses",(depth0 != null ? lookupProperty(depth0,"numberIncorrect") : depth0),{"name":"localizedString","hash":{},"data":data,"loc":{"start":{"line":11,"column":10},"end":{"line":11,"column":79}}}))
    + "</h3>\r\n      <span class=\"ui-accordion-header-right\">"
    + alias3((lookupProperty(helpers,"localizedString")||(depth0 && lookupProperty(depth0,"localizedString"))||alias2).call(alias1,"report.viewerContentWatched",{"name":"localizedString","hash":{},"data":data,"loc":{"start":{"line":12,"column":46},"end":{"line":12,"column":95}}}))
    + "</span>\r\n   </div>\r\n   <div>\r\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"allResponses") : depth0),{"name":"each","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":6},"end":{"line":31,"column":15}}})) != null ? stack1 : "")
    + "   </div>\r\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isIncorrect") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":6},"end":{"line":30,"column":13}}})) != null ? stack1 : "");
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <div class=\"viewer-data-details\">\r\n         <span class=\"viewer-data-watched\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"contentWatched") || (depth0 != null ? lookupProperty(depth0,"contentWatched") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"contentWatched","hash":{},"data":data,"loc":{"start":{"line":18,"column":43},"end":{"line":18,"column":61}}}) : helper)))
    + "</span>\r\n         <span class=\"viewer-data-question\">\r\n            <span class=\"viewer-data-mark-incorrect\">&#x2717;</span>\r\n            "
    + alias4(((helper = (helper = lookupProperty(helpers,"question") || (depth0 != null ? lookupProperty(depth0,"question") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"question","hash":{},"data":data,"loc":{"start":{"line":21,"column":12},"end":{"line":21,"column":24}}}) : helper)))
    + "\r\n         </span>\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasAnswer") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.program(10, data, 0),"data":data,"loc":{"start":{"line":23,"column":9},"end":{"line":28,"column":16}}})) != null ? stack1 : "")
    + "      </div>\r\n";
},"8":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "         <span class=\"viewer-data-answer-incorrect\">"
    + alias3((lookupProperty(helpers,"localizedString")||(depth0 && lookupProperty(depth0,"localizedString"))||alias2).call(alias1,"report.viewerAnswerGiven",{"name":"localizedString","hash":{},"data":data,"loc":{"start":{"line":24,"column":52},"end":{"line":24,"column":98}}}))
    + " "
    + alias3(((helper = (helper = lookupProperty(helpers,"answerGiven") || (depth0 != null ? lookupProperty(depth0,"answerGiven") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"answerGiven","hash":{},"data":data,"loc":{"start":{"line":24,"column":99},"end":{"line":24,"column":114}}}) : helper)))
    + "</span>\r\n         <span class=\"viewer-data-correct\">"
    + alias3((lookupProperty(helpers,"localizedString")||(depth0 && lookupProperty(depth0,"localizedString"))||alias2).call(alias1,"report.viewerCorrectAnswer",{"name":"localizedString","hash":{},"data":data,"loc":{"start":{"line":25,"column":43},"end":{"line":25,"column":91}}}))
    + " "
    + alias3(((helper = (helper = lookupProperty(helpers,"correctAnswer") || (depth0 != null ? lookupProperty(depth0,"correctAnswer") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"correctAnswer","hash":{},"data":data,"loc":{"start":{"line":25,"column":92},"end":{"line":25,"column":109}}}) : helper)))
    + "</span>\r\n";
},"10":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "         <span class=\"viewer-data-answer-ungraded\"><i>"
    + container.escapeExpression((lookupProperty(helpers,"localizedString")||(depth0 && lookupProperty(depth0,"localizedString"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"report.noAnswerGiven",{"name":"localizedString","hash":{},"data":data,"loc":{"start":{"line":27,"column":54},"end":{"line":27,"column":96}}}))
    + "</i></span>\r\n";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <div class=\"viewer-data-details\">\r\n         <span class=\"viewer-data-watched\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"contentWatched") || (depth0 != null ? lookupProperty(depth0,"contentWatched") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"contentWatched","hash":{},"data":data,"loc":{"start":{"line":41,"column":43},"end":{"line":41,"column":61}}}) : helper)))
    + "</span>\r\n         <span class=\"viewer-data-question\">\r\n            <span class=\"viewer-data-mark-"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isIncorrect") : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":43,"column":42},"end":{"line":43,"column":70}}})) != null ? stack1 : "")
    + "correct\">"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isScored") : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.program(20, data, 0),"data":data,"loc":{"start":{"line":43,"column":79},"end":{"line":43,"column":166}}})) != null ? stack1 : "")
    + "</span>\r\n            "
    + alias4(((helper = (helper = lookupProperty(helpers,"question") || (depth0 != null ? lookupProperty(depth0,"question") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"question","hash":{},"data":data,"loc":{"start":{"line":44,"column":12},"end":{"line":44,"column":24}}}) : helper)))
    + "\r\n         </span>\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasAnswer") : depth0),{"name":"if","hash":{},"fn":container.program(22, data, 0),"inverse":container.program(10, data, 0),"data":data,"loc":{"start":{"line":46,"column":9},"end":{"line":51,"column":16}}})) != null ? stack1 : "")
    + "      </div>\r\n";
},"13":function(container,depth0,helpers,partials,data) {
    return "in";
},"15":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isIncorrect") : depth0),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.program(18, data, 0),"data":data,"loc":{"start":{"line":43,"column":95},"end":{"line":43,"column":145}}})) != null ? stack1 : "");
},"16":function(container,depth0,helpers,partials,data) {
    return "&#x2717;";
},"18":function(container,depth0,helpers,partials,data) {
    return "&#x2713;";
},"20":function(container,depth0,helpers,partials,data) {
    return "&nbsp;";
},"22":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "         <span class=\"viewer-data-answer-"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isScored") : depth0),{"name":"if","hash":{},"fn":container.program(23, data, 0),"inverse":container.program(28, data, 0),"data":data,"loc":{"start":{"line":47,"column":41},"end":{"line":47,"column":130}}})) != null ? stack1 : "")
    + "\">"
    + alias3((lookupProperty(helpers,"localizedString")||(depth0 && lookupProperty(depth0,"localizedString"))||alias2).call(alias1,"report.viewerAnswerGiven",{"name":"localizedString","hash":{},"data":data,"loc":{"start":{"line":47,"column":132},"end":{"line":47,"column":178}}}))
    + " "
    + alias3(((helper = (helper = lookupProperty(helpers,"answerGiven") || (depth0 != null ? lookupProperty(depth0,"answerGiven") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"answerGiven","hash":{},"data":data,"loc":{"start":{"line":47,"column":179},"end":{"line":47,"column":194}}}) : helper)))
    + "</span>\r\n         "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isScored") : depth0),{"name":"if","hash":{},"fn":container.program(30, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":48,"column":9},"end":{"line":48,"column":139}}})) != null ? stack1 : "")
    + "\r\n";
},"23":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isIncorrect") : depth0),{"name":"if","hash":{},"fn":container.program(24, data, 0),"inverse":container.program(26, data, 0),"data":data,"loc":{"start":{"line":47,"column":57},"end":{"line":47,"column":107}}})) != null ? stack1 : "");
},"24":function(container,depth0,helpers,partials,data) {
    return "incorrect";
},"26":function(container,depth0,helpers,partials,data) {
    return "correct";
},"28":function(container,depth0,helpers,partials,data) {
    return "ungraded";
},"30":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<span class=\"viewer-data-correct\">"
    + alias3((lookupProperty(helpers,"localizedString")||(depth0 && lookupProperty(depth0,"localizedString"))||alias2).call(alias1,"report.viewerCorrectAnswer",{"name":"localizedString","hash":{},"data":data,"loc":{"start":{"line":48,"column":59},"end":{"line":48,"column":107}}}))
    + " "
    + alias3(((helper = (helper = lookupProperty(helpers,"correctAnswer") || (depth0 != null ? lookupProperty(depth0,"correctAnswer") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"correctAnswer","hash":{},"data":data,"loc":{"start":{"line":48,"column":108},"end":{"line":48,"column":125}}}) : helper)))
    + "</span>";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<h1>"
    + alias4(((helper = (helper = lookupProperty(helpers,"firstName") || (depth0 != null ? lookupProperty(depth0,"firstName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"firstName","hash":{},"data":data,"loc":{"start":{"line":1,"column":4},"end":{"line":1,"column":17}}}) : helper)))
    + " "
    + alias4(((helper = (helper = lookupProperty(helpers,"lastName") || (depth0 != null ? lookupProperty(depth0,"lastName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"lastName","hash":{},"data":data,"loc":{"start":{"line":1,"column":18},"end":{"line":1,"column":30}}}) : helper)))
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"email") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":30},"end":{"line":1,"column":62}}})) != null ? stack1 : "")
    + "</h1>\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasScoredQuestions") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":0},"end":{"line":7,"column":7}}})) != null ? stack1 : "")
    + "<div id=\"viewer-data-accordion\">\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasIncorrectResponses") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":3},"end":{"line":33,"column":10}}})) != null ? stack1 : "")
    + "   <div data-accordion-name=\"All Responses\">\r\n      <h3>"
    + alias4((lookupProperty(helpers,"localizedString")||(depth0 && lookupProperty(depth0,"localizedString"))||alias2).call(alias1,"report.viewerAllResponses",{"name":"localizedString","hash":{},"data":data,"loc":{"start":{"line":35,"column":10},"end":{"line":35,"column":57}}}))
    + "</h3>\r\n      <span class=\"ui-accordion-header-right\">"
    + alias4((lookupProperty(helpers,"localizedString")||(depth0 && lookupProperty(depth0,"localizedString"))||alias2).call(alias1,"report.viewerContentWatched",{"name":"localizedString","hash":{},"data":data,"loc":{"start":{"line":36,"column":46},"end":{"line":36,"column":95}}}))
    + "</span>\r\n   </div>\r\n   <div>\r\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"allResponses") : depth0),{"name":"each","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":39,"column":6},"end":{"line":53,"column":15}}})) != null ? stack1 : "")
    + "   </div>\r\n</div>";
},"useData":true});

templates['viewerStats'] = template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "   <span class=\"amazing-quiz-image\"></span>\r\n   <div class=\"amazing-quiz-text-container\">\r\n      <div class=\"amazing-quiz-text\">"
    + alias3((lookupProperty(helpers,"localizedString")||(depth0 && lookupProperty(depth0,"localizedString"))||alias2).call(alias1,"report.amazingQuizText1",{"name":"localizedString","hash":{},"data":data,"loc":{"start":{"line":4,"column":37},"end":{"line":4,"column":82}}}))
    + "<a id=\"download-details-report\" href=\"/Report/GetDetailsCsv/"
    + alias3(((helper = (helper = lookupProperty(helpers,"quizId") || (depth0 != null ? lookupProperty(depth0,"quizId") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"quizId","hash":{},"data":data,"loc":{"start":{"line":4,"column":142},"end":{"line":4,"column":152}}}) : helper)))
    + "\">"
    + alias3((lookupProperty(helpers,"localizedString")||(depth0 && lookupProperty(depth0,"localizedString"))||alias2).call(alias1,"report.amazingQuizDownloadDetailsCsv",{"name":"localizedString","hash":{},"data":data,"loc":{"start":{"line":4,"column":154},"end":{"line":4,"column":212}}}))
    + "</a>"
    + alias3((lookupProperty(helpers,"localizedString")||(depth0 && lookupProperty(depth0,"localizedString"))||alias2).call(alias1,"report.amazingQuizText2",{"name":"localizedString","hash":{},"data":data,"loc":{"start":{"line":4,"column":216},"end":{"line":4,"column":261}}}))
    + "</div>\r\n   </div>\r\n";
},"3":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "         <th>"
    + alias3((lookupProperty(helpers,"localizedString")||(depth0 && lookupProperty(depth0,"localizedString"))||alias2).call(alias1,"report.viewerCorrectHeader",{"name":"localizedString","hash":{},"data":data,"loc":{"start":{"line":18,"column":13},"end":{"line":18,"column":61}}}))
    + "</th>\r\n         <th>"
    + alias3((lookupProperty(helpers,"localizedString")||(depth0 && lookupProperty(depth0,"localizedString"))||alias2).call(alias1,"report.viewerPercentileHeader",{"name":"localizedString","hash":{},"data":data,"loc":{"start":{"line":19,"column":13},"end":{"line":19,"column":64}}}))
    + "</th>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isSummaryOnly") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":6,"column":7}}})) != null ? stack1 : "")
    + "<table class=\"tablesorter\">\r\n   <thead>\r\n      <tr>\r\n         <th class=\"displayNameDisplay\">"
    + alias3((lookupProperty(helpers,"localizedString")||(depth0 && lookupProperty(depth0,"localizedString"))||alias2).call(alias1,"report.viewerDisplayNameHeader",{"name":"localizedString","hash":{},"data":data,"loc":{"start":{"line":10,"column":40},"end":{"line":10,"column":92}}}))
    + "</th>\r\n         <th class=\"separateFirstAndLastNameDisplay\">"
    + alias3((lookupProperty(helpers,"localizedString")||(depth0 && lookupProperty(depth0,"localizedString"))||alias2).call(alias1,"report.viewerLastNameHeader",{"name":"localizedString","hash":{},"data":data,"loc":{"start":{"line":11,"column":53},"end":{"line":11,"column":102}}}))
    + "</th>\r\n         <th class=\"separateFirstAndLastNameDisplay\">"
    + alias3((lookupProperty(helpers,"localizedString")||(depth0 && lookupProperty(depth0,"localizedString"))||alias2).call(alias1,"report.viewerFirstNameHeader",{"name":"localizedString","hash":{},"data":data,"loc":{"start":{"line":12,"column":53},"end":{"line":12,"column":103}}}))
    + "</th>\r\n         <th class=\"firstAndLastNameTogetherDisplay\">"
    + alias3((lookupProperty(helpers,"localizedString")||(depth0 && lookupProperty(depth0,"localizedString"))||alias2).call(alias1,"report.viewerNameHeader",{"name":"localizedString","hash":{},"data":data,"loc":{"start":{"line":13,"column":53},"end":{"line":13,"column":98}}}))
    + "</th>\r\n         <th class=\"viewIdentityDisplay\">"
    + alias3((lookupProperty(helpers,"localizedString")||(depth0 && lookupProperty(depth0,"localizedString"))||alias2).call(alias1,"report.viewerHeader",{"name":"localizedString","hash":{},"data":data,"loc":{"start":{"line":14,"column":41},"end":{"line":14,"column":82}}}))
    + "</th>\r\n         <th class=\"emailDisplay\">"
    + alias3((lookupProperty(helpers,"localizedString")||(depth0 && lookupProperty(depth0,"localizedString"))||alias2).call(alias1,"report.viewerEmailHeader",{"name":"localizedString","hash":{},"data":data,"loc":{"start":{"line":15,"column":34},"end":{"line":15,"column":80}}}))
    + "</th>\r\n         <th>"
    + alias3((lookupProperty(helpers,"localizedString")||(depth0 && lookupProperty(depth0,"localizedString"))||alias2).call(alias1,"report.viewerStartTimeHeader",{"name":"localizedString","hash":{},"data":data,"loc":{"start":{"line":16,"column":13},"end":{"line":16,"column":63}}}))
    + "</th>\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasScoredQuestions") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":9},"end":{"line":20,"column":16}}})) != null ? stack1 : "")
    + "			<th>"
    + alias3((lookupProperty(helpers,"localizedString")||(depth0 && lookupProperty(depth0,"localizedString"))||alias2).call(alias1,"report.viewerContentWatchedHeader",{"name":"localizedString","hash":{},"data":data,"loc":{"start":{"line":21,"column":7},"end":{"line":21,"column":62}}}))
    + "</th>\r\n		</tr>\r\n	</thead>\r\n	<tbody class=\"viewer-list\"></tbody>\r\n</table>\r\n<div id=\"viewer-response-data\"></div>\r\n";
},"useData":true});
}());