(function() {
var template = Handlebars.template, templates = Handlebars.templates = Handlebars.templates || {};
templates['answerFillInTheBlank'] = template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "         <div data-index=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"index") || (depth0 != null ? lookupProperty(depth0,"index") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":6,"column":26},"end":{"line":6,"column":35}}}) : helper)))
    + "\" class=\"answer-container\">\r\n            <div class=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"noTouchClass") || (depth0 != null ? lookupProperty(depth0,"noTouchClass") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"noTouchClass","hash":{},"data":data,"loc":{"start":{"line":7,"column":24},"end":{"line":7,"column":40}}}) : helper)))
    + " answer\">\r\n               <!-- The path to noTouchClass here appears to have broken. -->\r\n               <input type=\"text\" name=\"answer\" class=\"fill-answer-response\" placeholder=\""
    + alias4((lookupProperty(helpers,"localizedString")||(depth0 && lookupProperty(depth0,"localizedString"))||alias2).call(alias1,"assessment.answerPlaceholder",{"name":"localizedString","hash":{},"data":data,"loc":{"start":{"line":9,"column":90},"end":{"line":9,"column":140}}}))
    + "\" value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"answerText") || (depth0 != null ? lookupProperty(depth0,"answerText") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"answerText","hash":{},"data":data,"loc":{"start":{"line":9,"column":149},"end":{"line":9,"column":163}}}) : helper)))
    + "\" />\r\n               <a class=\"delete-answer-button\" tabindex=\"-1\" data-index=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"index") || (depth0 != null ? lookupProperty(depth0,"index") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":10,"column":73},"end":{"line":10,"column":82}}}) : helper)))
    + "\" alt=\""
    + alias4((lookupProperty(helpers,"localizedString")||(depth0 && lookupProperty(depth0,"localizedString"))||alias2).call(alias1,"assessment.deleteAnswer",{"name":"localizedString","hash":{},"data":data,"loc":{"start":{"line":10,"column":89},"end":{"line":10,"column":134}}}))
    + "\">\r\n               </a>\r\n            </div>\r\n            <div class=\"characterCounter\">\r\n               <span>&nbsp</span>\r\n            </div>\r\n         </div>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"expanding-area-container\">\r\n   <span>"
    + alias3((lookupProperty(helpers,"localizedString")||(depth0 && lookupProperty(depth0,"localizedString"))||alias2).call(alias1,"assessment.answerLabel",{"name":"localizedString","hash":{},"data":data,"loc":{"start":{"line":2,"column":9},"end":{"line":2,"column":53}}}))
    + "</span>\r\n   <div class=\"expanding-area\">\r\n      <div class=\"answers\">\r\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"answerList") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":9},"end":{"line":17,"column":18}}})) != null ? stack1 : "")
    + "      </div>\r\n      <button class=\"largeButton addButton\">\r\n         <span>+ "
    + alias3((lookupProperty(helpers,"localizedString")||(depth0 && lookupProperty(depth0,"localizedString"))||alias2).call(alias1,"assessment.addAnswer",{"name":"localizedString","hash":{},"data":data,"loc":{"start":{"line":20,"column":17},"end":{"line":20,"column":59}}}))
    + "</span>\r\n      </button>\r\n   </div>\r\n</div>";
},"useData":true});

templates['answerMultipleChoice'] = template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "         <div data-index=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"index") || (depth0 != null ? lookupProperty(depth0,"index") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":6,"column":26},"end":{"line":6,"column":35}}}) : helper)))
    + "\" class=\"answer-container\">\r\n            <div class=\""
    + alias4(alias5((depths[1] != null ? lookupProperty(depths[1],"noTouchClass") : depths[1]), depth0))
    + " answer\">\r\n               <input type=\"checkbox\" id=\""
    + alias4(alias5((depths[1] != null ? lookupProperty(depths[1],"checkboxId") : depths[1]), depth0))
    + "_"
    + alias4(((helper = (helper = lookupProperty(helpers,"index") || (depth0 != null ? lookupProperty(depth0,"index") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":8,"column":60},"end":{"line":8,"column":69}}}) : helper)))
    + "\" "
    + ((stack1 = (lookupProperty(helpers,"equal")||(depth0 && lookupProperty(depth0,"equal"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"index") : depth0),(depths[1] != null ? lookupProperty(depths[1],"correctAnswerIndex") : depths[1]),{"name":"equal","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":71},"end":{"line":8,"column":135}}})) != null ? stack1 : "")
    + " name=\"correctAnswer"
    + alias4(((helper = (helper = lookupProperty(helpers,"index") || (depth0 != null ? lookupProperty(depth0,"index") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":8,"column":155},"end":{"line":8,"column":164}}}) : helper)))
    + "\" data-index=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"index") || (depth0 != null ? lookupProperty(depth0,"index") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":8,"column":178},"end":{"line":8,"column":187}}}) : helper)))
    + "\" />\r\n               <label for=\""
    + alias4(alias5((depths[1] != null ? lookupProperty(depths[1],"checkboxId") : depths[1]), depth0))
    + "_"
    + alias4(((helper = (helper = lookupProperty(helpers,"index") || (depth0 != null ? lookupProperty(depth0,"index") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":9,"column":45},"end":{"line":9,"column":54}}}) : helper)))
    + "\"><span></span></label>\r\n               <input type=\"text\" name=\"answer\" class=\"multiple-choice-answer-response\" placeholder=\""
    + alias4((lookupProperty(helpers,"localizedString")||(depth0 && lookupProperty(depth0,"localizedString"))||alias2).call(alias1,"assessment.answerPlaceholder",{"name":"localizedString","hash":{},"data":data,"loc":{"start":{"line":10,"column":101},"end":{"line":10,"column":151}}}))
    + "\" value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"answerText") || (depth0 != null ? lookupProperty(depth0,"answerText") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"answerText","hash":{},"data":data,"loc":{"start":{"line":10,"column":160},"end":{"line":10,"column":174}}}) : helper)))
    + "\" />\r\n               "
    + ((stack1 = (lookupProperty(helpers,"ifHasMultipleAnswers")||(depth0 && lookupProperty(depth0,"ifHasMultipleAnswers"))||alias2).call(alias1,(depths[1] != null ? lookupProperty(depths[1],"answerList") : depths[1]),{"name":"ifHasMultipleAnswers","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":15},"end":{"line":11,"column":206}}})) != null ? stack1 : "")
    + "\r\n            </div>\r\n            <div class=\"characterCounter\">\r\n               <span>&nbsp</span>\r\n            </div>\r\n         </div>\r\n";
},"2":function(container,depth0,helpers,partials,data) {
    return " checked=\"true\" ";
},"4":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<a class=\"delete-answer-button\" tabindex=\"-1\" data-index=\""
    + alias3(((helper = (helper = lookupProperty(helpers,"index") || (depth0 != null ? lookupProperty(depth0,"index") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":11,"column":112},"end":{"line":11,"column":121}}}) : helper)))
    + "\" alt=\""
    + alias3((lookupProperty(helpers,"localizedString")||(depth0 && lookupProperty(depth0,"localizedString"))||alias2).call(alias1,"assessment.deleteAnswer",{"name":"localizedString","hash":{},"data":data,"loc":{"start":{"line":11,"column":128},"end":{"line":11,"column":173}}}))
    + "\" /></a>";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"expanding-area-container\">\r\n   <span>"
    + alias3((lookupProperty(helpers,"localizedString")||(depth0 && lookupProperty(depth0,"localizedString"))||alias2).call(alias1,"assessment.answerLabel",{"name":"localizedString","hash":{},"data":data,"loc":{"start":{"line":2,"column":9},"end":{"line":2,"column":53}}}))
    + "</span>\r\n   <div class=\"expanding-area\">\r\n      <div class=\"answers\">\r\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"answerList") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":9},"end":{"line":17,"column":18}}})) != null ? stack1 : "")
    + "      </div>\r\n      <button class=\"largeButton addButton\">\r\n         <span>+ "
    + alias3((lookupProperty(helpers,"localizedString")||(depth0 && lookupProperty(depth0,"localizedString"))||alias2).call(alias1,"assessment.addChoice",{"name":"localizedString","hash":{},"data":data,"loc":{"start":{"line":20,"column":17},"end":{"line":20,"column":59}}}))
    + "</span>\r\n      </button>\r\n   </div>\r\n</div>\r\n";
},"useData":true,"useDepths":true});

templates['answerShortAnswer'] = template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"free-response-answer-container\">\r\n   <p>"
    + container.escapeExpression((lookupProperty(helpers,"localizedString")||(depth0 && lookupProperty(depth0,"localizedString"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"assessment.freeResponseMessage",{"name":"localizedString","hash":{},"data":data,"loc":{"start":{"line":2,"column":6},"end":{"line":2,"column":58}}}))
    + "</p>\r\n</div>";
},"useData":true});

templates['answerTrueFalse'] = template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "         <div data-index=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"index") || (depth0 != null ? lookupProperty(depth0,"index") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":6,"column":26},"end":{"line":6,"column":35}}}) : helper)))
    + "\" class=\"answer-container align-left\">\r\n            <div class=\""
    + alias4(alias5((depths[1] != null ? lookupProperty(depths[1],"noTouchClass") : depths[1]), depth0))
    + " answer\">\r\n               <input type=\"checkbox\" id=\""
    + alias4(alias5((depths[1] != null ? lookupProperty(depths[1],"checkboxId") : depths[1]), depth0))
    + "_"
    + alias4(((helper = (helper = lookupProperty(helpers,"index") || (depth0 != null ? lookupProperty(depth0,"index") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":8,"column":60},"end":{"line":8,"column":69}}}) : helper)))
    + "\" "
    + ((stack1 = (lookupProperty(helpers,"equal")||(depth0 && lookupProperty(depth0,"equal"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"index") : depth0),(depths[1] != null ? lookupProperty(depths[1],"correctAnswerIndex") : depths[1]),{"name":"equal","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":71},"end":{"line":8,"column":135}}})) != null ? stack1 : "")
    + " name=\"correctAnswer"
    + alias4(((helper = (helper = lookupProperty(helpers,"index") || (depth0 != null ? lookupProperty(depth0,"index") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":8,"column":155},"end":{"line":8,"column":164}}}) : helper)))
    + "\" data-index=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"index") || (depth0 != null ? lookupProperty(depth0,"index") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":8,"column":178},"end":{"line":8,"column":187}}}) : helper)))
    + "\" />\r\n               <label for=\""
    + alias4(alias5((depths[1] != null ? lookupProperty(depths[1],"checkboxId") : depths[1]), depth0))
    + "_"
    + alias4(((helper = (helper = lookupProperty(helpers,"index") || (depth0 != null ? lookupProperty(depth0,"index") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":9,"column":45},"end":{"line":9,"column":54}}}) : helper)))
    + "\"><span></span></label>\r\n               <span class=\"true-false-option\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"answerText") || (depth0 != null ? lookupProperty(depth0,"answerText") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"answerText","hash":{},"data":data,"loc":{"start":{"line":10,"column":47},"end":{"line":10,"column":61}}}) : helper)))
    + "</span>\r\n            </div>\r\n         </div>\r\n";
},"2":function(container,depth0,helpers,partials,data) {
    return " checked=\"true\" ";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"expanding-area-container\">\r\n   <span>"
    + container.escapeExpression((lookupProperty(helpers,"localizedString")||(depth0 && lookupProperty(depth0,"localizedString"))||container.hooks.helperMissing).call(alias1,"assessment.answerLabel",{"name":"localizedString","hash":{},"data":data,"loc":{"start":{"line":2,"column":9},"end":{"line":2,"column":53}}}))
    + "</span>\r\n   <div class=\"expanding-area\">\r\n      <div class=\"answers\">\r\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"answerList") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":9},"end":{"line":13,"column":18}}})) != null ? stack1 : "")
    + "      </div>\r\n   </div>\r\n</div>";
},"useData":true,"useDepths":true});

templates['discardButton'] = template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<input type=\"button\" value=\""
    + container.escapeExpression((lookupProperty(helpers,"localizedString")||(depth0 && lookupProperty(depth0,"localizedString"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"assessment.discardButtonText",{"name":"localizedString","hash":{},"data":data,"loc":{"start":{"line":1,"column":28},"end":{"line":1,"column":78}}}))
    + "\" class=\"l-discard-button s-discard-button s-edit-assessment-button\"/>";
},"useData":true});

templates['feedback'] = template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"feedback-add-container\">\r\n   <a class=\"feedback-expand-link largeButton\">"
    + alias3((lookupProperty(helpers,"localizedString")||(depth0 && lookupProperty(depth0,"localizedString"))||alias2).call(alias1,"assessment.addFeedback",{"name":"localizedString","hash":{},"data":data,"loc":{"start":{"line":2,"column":47},"end":{"line":2,"column":91}}}))
    + "</a>\r\n</div>\r\n<div class=\"feedback-collapsing-container\">\r\n   <div class=\"feedback-top-bar\">\r\n      <p>"
    + alias3((lookupProperty(helpers,"localizedString")||(depth0 && lookupProperty(depth0,"localizedString"))||alias2).call(alias1,"assessment.feedbackTitle",{"name":"localizedString","hash":{},"data":data,"loc":{"start":{"line":6,"column":9},"end":{"line":6,"column":55}}}))
    + "</p>\r\n      <a class=\"delete-button\">\r\n         <div class=\"delete-button-icon\"></div>\r\n      </a>\r\n   </div>\r\n   <div class=\"feedback-body\">\r\n      <div class=\"feedback-entry-container correct\">\r\n         <div class=\"expanding-area-container\">\r\n            <span>"
    + alias3((lookupProperty(helpers,"localizedString")||(depth0 && lookupProperty(depth0,"localizedString"))||alias2).call(alias1,"assessment.feedbackIfCorrect",{"name":"localizedString","hash":{},"data":data,"loc":{"start":{"line":14,"column":18},"end":{"line":14,"column":68}}}))
    + "</span>\r\n            <div name=\"text\" class=\"expanding-area\">\r\n               <div class=\"hideMe\">\r\n                  <span></span>\r\n                  <br />\r\n               </div>\r\n               <textarea class=\"feedback-text\" cols=\"20\" rows=\"1\" aria-label=\""
    + alias3((lookupProperty(helpers,"localizedString")||(depth0 && lookupProperty(depth0,"localizedString"))||alias2).call(alias1,"assessment.feedbackCorrectLabel",{"name":"localizedString","hash":{},"data":data,"loc":{"start":{"line":20,"column":78},"end":{"line":20,"column":131}}}))
    + "\"></textarea>\r\n            </div>\r\n            <div class=\"characterCounter\">\r\n               <span></span>\r\n            </div>\r\n            <label class=\"feedback-action-selection-container\">\r\n               "
    + alias3((lookupProperty(helpers,"localizedString")||(depth0 && lookupProperty(depth0,"localizedString"))||alias2).call(alias1,"assessment.feedbackAction",{"name":"localizedString","hash":{},"data":data,"loc":{"start":{"line":26,"column":15},"end":{"line":26,"column":62}}}))
    + "&nbsp;<select class=\"feedback-action-selector\">\r\n                  <option value=\"continue\">"
    + alias3((lookupProperty(helpers,"localizedString")||(depth0 && lookupProperty(depth0,"localizedString"))||alias2).call(alias1,"assessment.feedbackActionContinue",{"name":"localizedString","hash":{},"data":data,"loc":{"start":{"line":27,"column":43},"end":{"line":27,"column":98}}}))
    + "</option>\r\n                  <option value=\"jumpToTime\">"
    + alias3((lookupProperty(helpers,"localizedString")||(depth0 && lookupProperty(depth0,"localizedString"))||alias2).call(alias1,"assessment.feedbackActionJumpToTime",{"name":"localizedString","hash":{},"data":data,"loc":{"start":{"line":28,"column":45},"end":{"line":28,"column":102}}}))
    + "</option>\r\n                  <option value=\"goToUrl\">"
    + alias3((lookupProperty(helpers,"localizedString")||(depth0 && lookupProperty(depth0,"localizedString"))||alias2).call(alias1,"assessment.feedbackActionGoToUrl",{"name":"localizedString","hash":{},"data":data,"loc":{"start":{"line":29,"column":42},"end":{"line":29,"column":96}}}))
    + "</option>\r\n               </select>\r\n            </label>\r\n            <div class=\"feedback-go-to-url-container\">\r\n               <div>\r\n                  <input type=\"text\" class=\"feedback-go-to-url\" aria-label=\""
    + alias3((lookupProperty(helpers,"localizedString")||(depth0 && lookupProperty(depth0,"localizedString"))||alias2).call(alias1,"assessment.feedbackCorrectGoToUrlLabel",{"name":"localizedString","hash":{},"data":data,"loc":{"start":{"line":34,"column":76},"end":{"line":34,"column":136}}}))
    + "\" />\r\n               </div>\r\n               <div class=\"characterCounter\">\r\n                  <span></span>\r\n               </div>\r\n            </div>\r\n            <div class=\"feedback-jump-to-time-container\"><span class=\"feedback-time-format\">"
    + alias3((lookupProperty(helpers,"localizedString")||(depth0 && lookupProperty(depth0,"localizedString"))||alias2).call(alias1,"assessment.feedbackTimeFormatString",{"name":"localizedString","hash":{},"data":data,"loc":{"start":{"line":40,"column":92},"end":{"line":40,"column":149}}}))
    + "</span><div class=\"feedback-time-input-container\"><input class=\"feedback-jump-to-time minutes\" type=\"text\" />:<input class=\"feedback-jump-to-time seconds\" type=\"text\" />.<input class=\"feedback-jump-to-time milliseconds\" type=\"text\" /></div></div>\r\n         </div>\r\n      </div>\r\n      <div class=\"feedback-entry-container incorrect\">\r\n         <div class=\"expanding-area-container\">\r\n            <span>"
    + alias3((lookupProperty(helpers,"localizedString")||(depth0 && lookupProperty(depth0,"localizedString"))||alias2).call(alias1,"assessment.feedbackIfIncorrect",{"name":"localizedString","hash":{},"data":data,"loc":{"start":{"line":45,"column":18},"end":{"line":45,"column":70}}}))
    + "</span>\r\n            <div name=\"text\" class=\"expanding-area\">\r\n               <div class=\"hideMe\">\r\n                  <span></span>\r\n                  <br />\r\n               </div>\r\n               <textarea class=\"feedback-text\" cols=\"20\" rows=\"1\" aria-label=\""
    + alias3((lookupProperty(helpers,"localizedString")||(depth0 && lookupProperty(depth0,"localizedString"))||alias2).call(alias1,"assessment.feedbackIncorrectLabel",{"name":"localizedString","hash":{},"data":data,"loc":{"start":{"line":51,"column":78},"end":{"line":51,"column":133}}}))
    + "\"></textarea>\r\n            </div>\r\n            <div class=\"characterCounter\">\r\n               <span></span>\r\n            </div>\r\n            <label class=\"feedback-action-selection-container\">\r\n               "
    + alias3((lookupProperty(helpers,"localizedString")||(depth0 && lookupProperty(depth0,"localizedString"))||alias2).call(alias1,"assessment.feedbackAction",{"name":"localizedString","hash":{},"data":data,"loc":{"start":{"line":57,"column":15},"end":{"line":57,"column":62}}}))
    + "&nbsp;<select class=\"feedback-action-selector\">\r\n                  <option value=\"continue\">"
    + alias3((lookupProperty(helpers,"localizedString")||(depth0 && lookupProperty(depth0,"localizedString"))||alias2).call(alias1,"assessment.feedbackActionContinue",{"name":"localizedString","hash":{},"data":data,"loc":{"start":{"line":58,"column":43},"end":{"line":58,"column":98}}}))
    + "</option>\r\n                  <option value=\"jumpToTime\">"
    + alias3((lookupProperty(helpers,"localizedString")||(depth0 && lookupProperty(depth0,"localizedString"))||alias2).call(alias1,"assessment.feedbackActionJumpToTime",{"name":"localizedString","hash":{},"data":data,"loc":{"start":{"line":59,"column":45},"end":{"line":59,"column":102}}}))
    + "</option>\r\n                  <option value=\"goToUrl\">"
    + alias3((lookupProperty(helpers,"localizedString")||(depth0 && lookupProperty(depth0,"localizedString"))||alias2).call(alias1,"assessment.feedbackActionGoToUrl",{"name":"localizedString","hash":{},"data":data,"loc":{"start":{"line":60,"column":42},"end":{"line":60,"column":96}}}))
    + "</option>\r\n               </select>\r\n            </label>\r\n            <div class=\"feedback-go-to-url-container\">\r\n               <div>\r\n                  <input type=\"text\" class=\"feedback-go-to-url\" aria-label=\""
    + alias3((lookupProperty(helpers,"localizedString")||(depth0 && lookupProperty(depth0,"localizedString"))||alias2).call(alias1,"assessment.feedbackIncorrectGoToUrlLabel",{"name":"localizedString","hash":{},"data":data,"loc":{"start":{"line":65,"column":76},"end":{"line":65,"column":138}}}))
    + "\" />\r\n               </div>\r\n               <div class=\"characterCounter\">\r\n                  <span></span>\r\n               </div>\r\n            </div>\r\n            <div class=\"feedback-jump-to-time-container\"><span class=\"feedback-time-format\">"
    + alias3((lookupProperty(helpers,"localizedString")||(depth0 && lookupProperty(depth0,"localizedString"))||alias2).call(alias1,"assessment.feedbackTimeFormatString",{"name":"localizedString","hash":{},"data":data,"loc":{"start":{"line":71,"column":92},"end":{"line":71,"column":149}}}))
    + "</span><div class=\"feedback-time-input-container\"><input class=\"feedback-jump-to-time minutes\" type=\"text\" />:<input class=\"feedback-jump-to-time seconds\" type=\"text\" />.<input class=\"feedback-jump-to-time milliseconds\" type=\"text\" /></div></div>\r\n         </div>\r\n      </div>\r\n   </div>\r\n   <div class=\"feedback-unscored-placeholder\">\r\n      <span class=\"feedback-unscored-placeholder-text\">"
    + alias3((lookupProperty(helpers,"localizedString")||(depth0 && lookupProperty(depth0,"localizedString"))||alias2).call(alias1,"assessment.feedbackUnscoredPlaceholder",{"name":"localizedString","hash":{},"data":data,"loc":{"start":{"line":76,"column":55},"end":{"line":76,"column":115}}}))
    + "</span>\r\n   </div>\r\n</div>";
},"useData":true});

templates['gearButton'] = template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    return "<input type=\"button\" class=\"s-gear-button l-gear-button\" />";
},"useData":true});

templates['image'] = template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"minimum-height-spacer\">\r\n   <img class=\"media-image\" src=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"mediaUrl") || (depth0 != null ? lookupProperty(depth0,"mediaUrl") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"mediaUrl","hash":{},"data":data,"loc":{"start":{"line":2,"column":33},"end":{"line":2,"column":45}}}) : helper)))
    + "\">\r\n</div>";
},"useData":true});

templates['publishButton'] = template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<input type=\"button\" value=\""
    + container.escapeExpression((lookupProperty(helpers,"localizedString")||(depth0 && lookupProperty(depth0,"localizedString"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"assessment.publish",{"name":"localizedString","hash":{},"data":data,"loc":{"start":{"line":1,"column":28},"end":{"line":1,"column":68}}}))
    + "\" class=\"l-publish-button s-publish-button s-edit-assessment-button\"/>";
},"useData":true});

templates['publishOptions'] = template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"user-options\">\r\n   <input type=\"checkbox\" class=\"user-option-input\" name=\"sendNightlyEmails\" id=\"send-nightly-emails\"/>\r\n   <label class=\"user-option-label\" for=\"send-nightly-emails\">"
    + alias3((lookupProperty(helpers,"localizedString")||(depth0 && lookupProperty(depth0,"localizedString"))||alias2).call(alias1,"assessment.shouldSendEmail",{"name":"localizedString","hash":{},"data":data,"loc":{"start":{"line":3,"column":62},"end":{"line":3,"column":110}}}))
    + "</label>\r\n</div>\r\n<input type=\"text\" name=\"email\" id=\"quizEmail\" value=\""
    + alias3(((helper = (helper = lookupProperty(helpers,"email") || (depth0 != null ? lookupProperty(depth0,"email") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"email","hash":{},"data":data,"loc":{"start":{"line":5,"column":54},"end":{"line":5,"column":63}}}) : helper)))
    + "\"  />\r\n<div id=\"user-options\">\r\n   <input type=\"checkbox\" class=\"user-option-input\" name=\"requireUserId\" id=\"require-user-id\"/>\r\n   <label class=\"user-option-label\" for=\"require-user-id\">"
    + alias3((lookupProperty(helpers,"localizedString")||(depth0 && lookupProperty(depth0,"localizedString"))||alias2).call(alias1,"assessment.quizEmailLabel",{"name":"localizedString","hash":{},"data":data,"loc":{"start":{"line":8,"column":58},"end":{"line":8,"column":105}}}))
    + "</label>\r\n</div>\r\n";
},"useData":true});

templates['question'] = template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"question-top-bar\">\r\n   <div class=\"question-details\">\r\n      <div class=\"question-number\"></div>\r\n   </div>\r\n   <div class=\"question-type-button-container\">\r\n      <!--The question-types-button element can be reverted to a button after Firefox's November release, 2016.  The bug is documented here: https://bugzilla.mozilla.org/show_bug.cgi?id=984869#c2 -->\r\n      <div class=\"question-types-button\" alt=\""
    + alias3((lookupProperty(helpers,"localizedString")||(depth0 && lookupProperty(depth0,"localizedString"))||alias2).call(alias1,"assessment.questionTypes",{"name":"localizedString","hash":{},"data":data,"loc":{"start":{"line":7,"column":46},"end":{"line":7,"column":92}}}))
    + "\">\r\n         <div class=\"question-types-button-icon\"></div>\r\n         <div class=\"question-types-button-text\"></div>\r\n         <div class=\"question-types-button-downarrow-icon\"></div>\r\n      </div>\r\n   </div>\r\n   <div class=\"question-delete-container\">\r\n      <a class=\"delete-button\" alt=\""
    + alias3((lookupProperty(helpers,"localizedString")||(depth0 && lookupProperty(depth0,"localizedString"))||alias2).call(alias1,"assessment.deleteQuestion",{"name":"localizedString","hash":{},"data":data,"loc":{"start":{"line":14,"column":36},"end":{"line":14,"column":83}}}))
    + "\">\r\n         <div class=\"delete-button-icon\"></div>\r\n      </a>\r\n   </div>\r\n</div>\r\n<div class=\"question-select-drawer\">\r\n   <div class=\"question-type-list-container\">\r\n      <ul>\r\n         <li class=\"multiple-choice-type-button\">\r\n            <a data-questionType=\"multiple-choice\" href=\"#\">"
    + alias3((lookupProperty(helpers,"localizedString")||(depth0 && lookupProperty(depth0,"localizedString"))||alias2).call(alias1,"common.multipleChoice",{"name":"localizedString","hash":{},"data":data,"loc":{"start":{"line":23,"column":60},"end":{"line":23,"column":103}}}))
    + "</a>\r\n         </li>\r\n         <li class=\"true-false-type-button\">\r\n            <a data-questionType=\"true-false\" href=\"#\">"
    + alias3((lookupProperty(helpers,"localizedString")||(depth0 && lookupProperty(depth0,"localizedString"))||alias2).call(alias1,"common.trueFalse",{"name":"localizedString","hash":{},"data":data,"loc":{"start":{"line":26,"column":55},"end":{"line":26,"column":93}}}))
    + "</a>\r\n         </li>\r\n         <li class=\"fill-in-the-blank-type-button\">\r\n            <a data-questionType=\"fill-in-the-blank\" href=\"#\">"
    + alias3((lookupProperty(helpers,"localizedString")||(depth0 && lookupProperty(depth0,"localizedString"))||alias2).call(alias1,"common.fillInTheBlank",{"name":"localizedString","hash":{},"data":data,"loc":{"start":{"line":29,"column":62},"end":{"line":29,"column":105}}}))
    + "</a>\r\n         </li>\r\n         <li class=\"short-answer-type-button\">\r\n            <a data-questionType=\"short-answer\" href=\"#\">"
    + alias3((lookupProperty(helpers,"localizedString")||(depth0 && lookupProperty(depth0,"localizedString"))||alias2).call(alias1,"common.shortAnswer",{"name":"localizedString","hash":{},"data":data,"loc":{"start":{"line":32,"column":57},"end":{"line":32,"column":97}}}))
    + "</a>\r\n         </li>\r\n      </ul>\r\n      <select class=\"question-type-list-select\">\r\n         <option value=\"fill-in-the-blank\">"
    + alias3((lookupProperty(helpers,"localizedString")||(depth0 && lookupProperty(depth0,"localizedString"))||alias2).call(alias1,"common.fillInTheBlank",{"name":"localizedString","hash":{},"data":data,"loc":{"start":{"line":36,"column":43},"end":{"line":36,"column":86}}}))
    + "</option>\r\n         <option value=\"short-answer\">"
    + alias3((lookupProperty(helpers,"localizedString")||(depth0 && lookupProperty(depth0,"localizedString"))||alias2).call(alias1,"common.shortAnswer",{"name":"localizedString","hash":{},"data":data,"loc":{"start":{"line":37,"column":38},"end":{"line":37,"column":78}}}))
    + "</option>\r\n         <option value=\"multiple-choice\">"
    + alias3((lookupProperty(helpers,"localizedString")||(depth0 && lookupProperty(depth0,"localizedString"))||alias2).call(alias1,"common.multipleChoice",{"name":"localizedString","hash":{},"data":data,"loc":{"start":{"line":38,"column":41},"end":{"line":38,"column":84}}}))
    + "</option>\r\n         <option value=\"true-false\">"
    + alias3((lookupProperty(helpers,"localizedString")||(depth0 && lookupProperty(depth0,"localizedString"))||alias2).call(alias1,"common.trueFalse",{"name":"localizedString","hash":{},"data":data,"loc":{"start":{"line":39,"column":36},"end":{"line":39,"column":74}}}))
    + "</option>\r\n      </select>\r\n   </div>\r\n</div>\r\n<div class=\"question-and-answer-container\">\r\n   <div class=\"question\">\r\n      <div class=\"expanding-area-container\">\r\n         <span>"
    + alias3((lookupProperty(helpers,"localizedString")||(depth0 && lookupProperty(depth0,"localizedString"))||alias2).call(alias1,"assessment.questionLabel",{"name":"localizedString","hash":{},"data":data,"loc":{"start":{"line":46,"column":15},"end":{"line":46,"column":61}}}))
    + "</span>\r\n         <div name=\"text\" class=\"expanding-area\">\r\n            <div class=\"hideMe\">\r\n               <span></span>\r\n               <br />\r\n            </div>\r\n            <textarea class=\"question-text\" cols=\"20\" rows=\"1\" placeholder=\""
    + alias3((lookupProperty(helpers,"localizedString")||(depth0 && lookupProperty(depth0,"localizedString"))||alias2).call(alias1,"assessment.questionPlaceholder",{"name":"localizedString","hash":{},"data":data,"loc":{"start":{"line":52,"column":76},"end":{"line":52,"column":128}}}))
    + "\" aria-label=\""
    + alias3((lookupProperty(helpers,"localizedString")||(depth0 && lookupProperty(depth0,"localizedString"))||alias2).call(alias1,"assessment.questionLabel",{"name":"localizedString","hash":{},"data":data,"loc":{"start":{"line":52,"column":142},"end":{"line":52,"column":188}}}))
    + "\"></textarea>\r\n         </div>\r\n         <div class=\"characterCounter\">\r\n            <span></span>\r\n         </div>\r\n      </div>\r\n   </div>\r\n   <div class=\"answers-container\">\r\n   </div>\r\n</div>\r\n<div class=\"feedback-container\"></div>";
},"useData":true});

templates['questionSet'] = template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    return "<div class=\"question-list\"/>";
},"useData":true});

templates['quizSaveStatus'] = template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"l-quiz-saving hide s-quiz-save-status inline-block\">"
    + alias3((lookupProperty(helpers,"localizedString")||(depth0 && lookupProperty(depth0,"localizedString"))||alias2).call(alias1,"assessment.autoSaving",{"name":"localizedString","hash":{},"data":data,"loc":{"start":{"line":1,"column":64},"end":{"line":1,"column":107}}}))
    + "</div>\r\n<div class=\"l-quiz-save-status s-quiz-save-status inline-block\">\r\n   "
    + alias3((lookupProperty(helpers,"localizedString")||(depth0 && lookupProperty(depth0,"localizedString"))||alias2).call(alias1,"assessment.savedAt",{"name":"localizedString","hash":{},"data":data,"loc":{"start":{"line":3,"column":3},"end":{"line":3,"column":43}}}))
    + " "
    + alias3(((helper = (helper = lookupProperty(helpers,"lastSaveTime") || (depth0 != null ? lookupProperty(depth0,"lastSaveTime") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"lastSaveTime","hash":{},"data":data,"loc":{"start":{"line":3,"column":44},"end":{"line":3,"column":60}}}) : helper)))
    + "\r\n</div>";
},"useData":true});

templates['quizTitle'] = template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<input type=\"text\" id=\"quizTitle\" class=\"assessment-title\" value=\""
    + alias3(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":1,"column":66},"end":{"line":1,"column":75}}}) : helper)))
    + "\" placeholder=\""
    + alias3((lookupProperty(helpers,"localizedString")||(depth0 && lookupProperty(depth0,"localizedString"))||alias2).call(alias1,"assessment.quizTitlePlaceholder",{"name":"localizedString","hash":{},"data":data,"loc":{"start":{"line":1,"column":90},"end":{"line":1,"column":143}}}))
    + "\" />";
},"useData":true});

templates['returnToMediaButton'] = template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<input type=\"button\" value=\""
    + container.escapeExpression((lookupProperty(helpers,"localizedString")||(depth0 && lookupProperty(depth0,"localizedString"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"assessment.returnToVideoButtonText",{"name":"localizedString","hash":{},"data":data,"loc":{"start":{"line":1,"column":28},"end":{"line":1,"column":84}}}))
    + "\" class=\"l-return-to-media-button s-return-to-media-button s-edit-assessment-button hide\" />";
},"useData":true});

templates['shareButton'] = template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<input type=\"button\" value=\""
    + container.escapeExpression((lookupProperty(helpers,"localizedString")||(depth0 && lookupProperty(depth0,"localizedString"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"assessment.saveButtonText",{"name":"localizedString","hash":{},"data":data,"loc":{"start":{"line":1,"column":28},"end":{"line":1,"column":75}}}))
    + "\" class=\"l-share-button s-share-button s-edit-assessment-button\"/>";
},"useData":true});

templates['shareDetails'] = template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"share-link\">\r\n  <label for=\"shareLinkField\">"
    + alias3((lookupProperty(helpers,"localizedString")||(depth0 && lookupProperty(depth0,"localizedString"))||alias2).call(alias1,"assessment.shareLinkAssessment",{"name":"localizedString","hash":{},"data":data,"loc":{"start":{"line":2,"column":30},"end":{"line":2,"column":82}}}))
    + "</label>\r\n  <input type=\"text\" id=\"shareLinkField\" />\r\n  <div class=\"copyInstructions\">"
    + alias3((lookupProperty(helpers,"localizedString")||(depth0 && lookupProperty(depth0,"localizedString"))||alias2).call(alias1,"assessment.shareReadyToCopy",{"name":"localizedString","hash":{},"data":data,"loc":{"start":{"line":4,"column":32},"end":{"line":4,"column":81}}}))
    + "</div>\r\n</div>\r\n<div class=\"share-embed\">\r\n  <label for=\"shareEmbedCode\">"
    + alias3((lookupProperty(helpers,"localizedString")||(depth0 && lookupProperty(depth0,"localizedString"))||alias2).call(alias1,"assessment.shareEmbedVideo",{"name":"localizedString","hash":{},"data":data,"loc":{"start":{"line":7,"column":30},"end":{"line":7,"column":78}}}))
    + "</label>\r\n  <input type=\"text\" id=\"shareEmbedCode\" />\r\n  <div class=\"copyInstructions\">"
    + alias3((lookupProperty(helpers,"localizedString")||(depth0 && lookupProperty(depth0,"localizedString"))||alias2).call(alias1,"assessment.shareReadyToCopy",{"name":"localizedString","hash":{},"data":data,"loc":{"start":{"line":9,"column":32},"end":{"line":9,"column":81}}}))
    + "</div>\r\n</div>";
},"useData":true});

templates['shortAnswer'] = template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    return "<div class=\"answer-container\">\r\n   <div class=\"answer\">\r\n      <input type=\"text\" name=\"answer\" class=\"short-answer-response\" placeholder=\"(Optional) Answer\"/>\r\n   </div>\r\n   <div class=\"characterCounter\">\r\n      <span>&nbsp;</span>\r\n   </div>\r\n</div>";
},"useData":true});

templates['video'] = template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"video-backdrop v-centered-content\">\r\n   <video preload=\"none\">\r\n      <source src=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"mediaUrl") || (depth0 != null ? lookupProperty(depth0,"mediaUrl") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"mediaUrl","hash":{},"data":data,"loc":{"start":{"line":3,"column":19},"end":{"line":3,"column":31}}}) : helper)))
    + "\" type=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"mediaType") || (depth0 != null ? lookupProperty(depth0,"mediaType") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"mediaType","hash":{},"data":data,"loc":{"start":{"line":3,"column":39},"end":{"line":3,"column":52}}}) : helper)))
    + "\">\r\n   </video>\r\n</div>";
},"useData":true});
}());